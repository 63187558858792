<template>
  <div>
    <header class="container header_white">
      <Navbar logoColor="true"></Navbar>
    </header>

    <div class="bg_image-arrow" style="min-height: 70vh">
      <div class="container">

        <!-- PROFILE -->
        <section v-if="isLoading" class="row">
          <div class="col-12 text-center">
            <Loader></Loader>
          </div>
        </section>
        <div v-else class="row">

          <div class="mainContent col-12 px-0 researcherProfile">
            <div class="user-content row pt-5">
              <div class="col-12 px-0">
                <div class="cardChart bg_white px-0 px-lg-4 mx-3 pt-4 pb-2 row image-container text-center text-lg-left position-relative align-items-center">

                  <figure class="col-12 col-md-3 col-lg-2 image mt-3 pt-3 pt-md-0">
                    <img
                        v-if="!profile.personal_info.image_url"
                        class="rounded-circle"
                        :src="require(`@/assets/default.png`)"
                        width="100"
                        height="100"
                        alt=""
                    />
                    <img
                        v-else
                        class="rounded-circle"
                        :src="profile.personal_info.image_url"
                        width="100"
                        height="100"
                        alt=""
                    />
                  </figure>
                  <h3 class="title_invert title_m col-12 col-md-9 col-lg-8 my-3 pl-lg-0 pt-lg-3">
                    {{ profile.personal_info.name }} <br/>
                    <p class="text_l text_invert mb-0 text_normal d-inline-block mr-1"
                       v-if="profile.personal_info.country">
                      {{ profile.personal_info.country }},
                    </p>
                    <p class="text_l text_invert mb-0 text_normal d-inline-block">
                      {{ profile.personal_info.city }}
                    </p>
                    <br/>
                    <p v-if="profile.work_info.page_url.name" class="text_l text_invert mt-2">
                      {{ profile.work_info.page_url.title }}:
                      <a class="text-decoration-underline text_invert" :href="profile.work_info.page_url.name"
                         target="_blank">
									<span>
										{{ profile.work_info.page_url.name }}
									</span>
                      </a>
                    </p>
                  </h3>

                  <div class="col-12 my-3">
                    <div class="border-bottom"></div>
                  </div>
                  <div class="col-12">
                    <p v-if="profile.research_info.page_url.name" class="text_l text_invert mt-2">
                      {{ profile.research_info.page_url.title }}:
                      <a class="text-decoration-underline text_invert" :href="profile.research_info.page_url.name"
                         target="_blank">
									<span>
										{{ profile.research_info.page_url.name }}
									</span>
                      </a>
                    </p>
                  </div>
                </div>

                <div class="cardChart bg_white py-4 px-0 px-lg-4 row my-4 mx-3">
                  <h4 class="col-12 title_m title_darkblue mb-4 pb-1 text_normal">
                    Informacije o istraživaču
                  </h4>

                  <div class="col-12 px-0">
                    <div class="row">
                      <div v-if="profile.work_info" class="col-12 col-lg-6">
                        <div v-for="i in profile.work_info" :key="i.title" class="col-12">
                          <h5 class="text_xl mb-1 title_darkblue text_normal">
                            {{ i.title }}
                          </h5>
                          <p v-if="i.title == 'Lična stranica'" class="text_l text_normal">
                            <a class="text-decoration-underline " :href="i.name" target="_blank">
                              {{ i.name }}
                            </a>
                          </p>
                          <p v-else class="text_l">
                            {{ i.name }}
                          </p>
                        </div>
                      </div>
                      <div v-if="profile.research_info" class="col-12 col-lg-6">
                        <div v-for="i in profile.research_info" :key="i.title" class="col-12">
                          <h5 class="text_xl mb-1 title_darkblue text_normal">
                            {{ i.title }}
                          </h5>
                          <p v-if="i.title == 'Teme istraživanja'" class="text_l">
                            <span v-for="topic in i.name.split(',')" :key="topic" class="badge badge-light py-1 px-2 mr-2">
                              {{ topic }}
                            </span>
                          </p>
                          <p v-else-if="i.title == 'Stranica sa radovima'" class="text_l">
                            <a class="text-decoration-underline " :href="i.name" target="_blank">
                              {{ i.name }}
                            </a>
                          </p>
                          <p v-else class="text_l">
                            {{ i.name }}
                          </p>
                        </div>
                      </div>
                      <div class="col-12 my-3">
                        <div class="border-bottom"></div>
                      </div>
                      <div v-if="profile.bio.name" class="col-12">
                        <div class="col-12">
                          <h5 class="text_xl mb-1 title_darkblue text_normal">
                            {{ profile.bio.title }}
                          </h5>
                          <p class="text_l">
                            {{ profile.bio.name }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <Footer bg="footer_bg-red"></Footer>
  </div>
</template>

<script>
import axios from 'axios';
import Loader from "@/components/shared/Loader";
import Navbar from "@/components/layout/Navbar";
import Footer from "@/components/layout/Footer";

export default {
  name: 'ResearcherProfile',
  components: {
    Loader,
    Navbar,
    Footer
  },
  data() {
    return {
      isLoading: false,
      profileUrl: null,
      profile: {
        personal_info: {},
        work_info: {},
        research_info: {},
        bio: {}
      }
    };
  },
  methods: {
    fetchProfile() {
      this.isLoading = true;
      const requestData = {
        profileUrl : this.$route.params.url
      };

      axios.post(`${process.env.VUE_APP_API_URL}/researchers/profile/?url=${this.$route.params.url}`)
          .then(response => {
            this.profile = response.data;
            this.isLoading = false;
          })
          .catch(error => {
            console.error('Error fetching profile:', error);
            this.isLoading = false;
          });
    }
  },
  created() {
    this.fetchProfile();
  }
};
</script>

